<template>
    <div class="action-recommend-request">
        <div class="title" v-html="title" />
        <div class="body" v-html="body" v-if="body" />
    </div>
</template>
<script>
export default {
    name: 'ActionRecommendRequest',
    props: ['message'],
    computed: {
        title() {
            if ((this.users || []).length === 0) return

            return this.$translate('ACTION_RECOMMEND_REQUEST_TITLE').replace(
                /%s/,
                this.users.length === 1 ? `${this.users[0].nickname}님` : `${this.users.length}명`
            )
        },
        body() {
            if ((this.users || []).length === 0) return

            return this.$translate('ACTION_RECOMMEND_REQUEST_BODY').replace(
                /%s/,
                this.users.length === 1 ? `${this.users[0].nickname}님` : '추천회원'
            )
        },
        users() {
            return (this.$mustParse(this.message.content) || {}).users || []
        },
        chatUser() {
            return (this.$store.getters.chat || {}).user
        },
    },
    methods: {
        async onClickProfile(user) {
            try {
                await this.$store.dispatch('loadIntroducingUser', user.id)
                if (this.$store.getters.isMobile) {
                    this.$router.push({
                        name: 'UserDetailPage',
                        params: {
                            userId: user.id,
                        },
                    })
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>
